// viewport
(function() {
  // var _isTab;
  var _ua = navigator.userAgent;
  // ▼タブレット
  if ((_ua.indexOf('Android') > 0 && _ua.indexOf('Mobile') == -1) || _ua.indexOf('iPad') > 0 || _ua.indexOf('Kindle') > 0 || _ua.indexOf('Silk') > 0) {
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=1200');
    // document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width');
  // ▼スマホios
  } else if (_ua.indexOf('iPhone') > 0) {
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, user-scalable=no');
  // ▼スマホdoroid
  } else if (_ua.indexOf('Android') > 0 && _ua.indexOf('Mobile') > 0) {
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width');
  // ▼PC
  } else {
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=1000');
  }
  // console.log(_isTab);
}());
